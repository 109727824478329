import Apis from '@/definitions/apis'

const utils = {

  /**
   * Returns the URL of the API base on a given API keyname parameter.
   * Some urls include path params, those can be passed in as an object parameter.
   *
   * @param api: string   api keyname
   * @param pathParams: object  path parameter k,v object
   * @param queryParams: object  query parameter k,v object
   * @returns string  decorated api url to fetch
   */
  url: (api: string,
    pathParams: object | null = null,
    queryParams: object | null = null): string => {
    const hostName: string = utils.apiHost()
    let apiPath: string = Apis[api]

    if (pathParams) {
      Object.keys(pathParams).map((k: string): string => {
        apiPath = apiPath.replace(`:${k}`, pathParams[k])
        return apiPath
      })
    }
    if (queryParams) {
      const strArray: Array<string> = Object.keys(queryParams).reduce(
        (o: Array<string>, i: string): Array<string> => {
          o.push(`${i}=${queryParams[i]}`)
          return o
        },
        [],
      )

      const queryString: string = strArray ? strArray.join('&') : null
      if (queryString) apiPath = `${apiPath}?${queryString}`
    }
    return `${hostName}${apiPath}`
  },

  /**
   * Returns the API hostname based on NODE_ENV
   *
   * @returns string  hostname of api urls
   */
  apiHost: (): string => ((process.env.NODE_ENV === 'production') ? Apis.prodHost : Apis.devHost),
}
export default utils
