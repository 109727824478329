import Vue from 'vue'
import Vuex, { Store } from 'vuex'
// eslint-disable-next-line import/extensions
import { VuexState } from '@/interfaces/main.d.ts'

Vue.use(Vuex)

const stateInstance: VuexState = {
  activityQueue: null,
  uploadingFile: null,
}

const store: Store<VuexState> = new Vuex.Store({
  strict: true,
  state: stateInstance,
  mutations: {
    changeActivityQueue(state, newQueue) {
      state.activityQueue = newQueue
    },
    changeUploadingFile(state, file) {
      state.uploadingFile = file
    },
  },
})

export default store
