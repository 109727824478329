import Dayjs from 'dayjs'

const utils = {

  logError: (err: string): void => {
    const s = localStorage.error
    const now = Dayjs().toISOString()
    const errorMsg = `------------------\n${now}\n${err}\n`
    const msg: string = (s && s !== 'none') ? `${s}${errorMsg}` : errorMsg
    localStorage.error = msg
  },

  resetError: (): void => {
    localStorage.error = 'none'
  },
}
export default utils
