import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import Security from '@/utilities/Security'
import Urls from '../utilities/Url'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    meta: { requiresAuth: true },
    component: () => import('../views/General/Home.vue'),
  },
  {
    path: '/home',
    name: 'Home',
    meta: { requiresAuth: true },
    component: () => import('../views/General/Home.vue'),
  },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  {
    path: '/about',
    name: 'About',
    meta: { requiresAuth: true },
    component: () => import('../views/General/About.vue'),
  },
  {
    path: '/list/:list',
    name: 'List',
    meta: { requiresAuth: true },
    component: () => import('../views/List/List.vue'),
  },
  {
    path: '/book/:bookid',
    name: 'Book',
    meta: { requiresAuth: true },
    component: () => import('../views/Photobooks/Photobook.vue'),
  },
  {
    path: '/upload/:uploadid',
    name: 'Upload',
    meta: { requiresAuth: true },
    component: () => import('../views/Uploads/Upload.vue'),
  },
  {
    path: '/addphotos',
    name: 'AddPhotos',
    meta: { requiresAuth: true },
    component: () => import('../views/Uploads/AddPhotos.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: { requiresAuth: false },
    component: () => import('../views/Auth/Login.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: { requiresAuth: false },
    component: () => import('../views/Auth/Logout.vue'),
  },
  {
    path: '/test',
    name: 'Test',
    meta: { requiresAuth: false },
    component: () => import('../views/General/Test.vue'),
  },
  {
    path: '*',
    name: 'Error',
    meta: { requiresAuth: false },
    component: () => import('../views/General/Error.vue'),
  },
]

const router: VueRouter = new VueRouter({
  mode: 'history',
  routes,
})

/**
 * Record routes in session
 * @param route
 */
const logRoute = (route: string): Promise<void> => new Promise((resolve, reject) => {
  const request = { route }
  fetch(Urls.url('route'), {
    method: 'POST',
    headers: { Authorization: localStorage.tk },
    body: JSON.stringify(request),
  }).then(() => resolve()).catch(() => resolve())
})

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  if (to.matched.some((record) => record.meta.requiresAuth)
    && !Security.validToken(localStorage.tk)) {
    next({
      path: '/login',
      params: { nextUrl: to.fullPath },
    })
  } else {
    logRoute(to.fullPath)
    next()
  }
})

export default router
