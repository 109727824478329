import Apis from '@/definitions/apis'
import Errors from '@/utilities/Errors'
import { Base64 } from 'js-base64'
import Dayjs from 'dayjs'

const utils = {

  /**
   * Checks a jwt to see if it has the correct issuer and is not expired
   * @param token   JWT to validate
   * @returns boolean   returns true if the token is not expired and issue is correct
   */
  validToken: (token: string): boolean => {
    if (!token) return false
    const parts = token.split('.')
    if (parts.length !== 3) {
      Errors.logError('Token content is not a JWT')
      return false
    }
    try {
      // const head = JSON.parse(Base64.decode(parts[0]))
      const body = JSON.parse(Base64.decode(parts[1]))
      const now = Dayjs().unix()
      if (body.iss !== Apis.iss) {
        Errors.logError('JWT has incorrect issuer')
        return false
      }
      if (body.exp < now) {
        Errors.logError('JWT expired')
        return false
      }
      return true
    } catch (e) {
      Errors.logError(`validateToken failed -> ${e}`)
      return false
    }
  },
}
export default utils
