import {
  Map,
  CreatePicResponse,
  UploadQueue,
  VuexState,
// eslint-disable-next-line import/extensions
} from '@/interfaces/main.d.ts'
import { Store } from 'vuex'
import Urls from '@/utilities/Url'
import Errors from '@/utilities/Errors'
import Md5 from 'md5'

declare const Buffer

/**
 * An instance of this class is created to upload photos
 */
export default class Uploader {
  running: boolean;

  uploadId: string;

  uploadName: string;

  constructor() {
    this.running = false
  }

  /**
   * Starts the upload process for files in the Vuex upload queue
   *
   * @param store: Store<VuexState> Vuex store containing the upload file queue
   */
  start(store: Store<VuexState>): void {
    if (!this.running) {
      const queue: UploadQueue = store.state.activityQueue
      if (queue) {
        this.uploadId = queue.upload_id
        this.uploadName = queue.name
        this.running = true
        this.processUploadList(store)
      } else {
        this.running = false
      }
    }
  }

  /**
   * Uploads the files in the activity queue in Vuex
   *
   * Function looks for existing pics in the upload queue,
   * uploads the first one,
   * updates vuex with a new queue minus the uploaded file,
   * then call itself (processUploadList function) again
   *
   * @param store: Store<VuexState>   Vuex store containing the upload file queue
   * @returns void
   */
  private processUploadList(store: Store<VuexState>): void {
    const queue: UploadQueue = store.state.activityQueue
    if (queue && queue.pics && Object.keys(queue.pics)[0]) {
      const key: string = Object.keys(queue.pics)[0]
      const file: File = queue.pics[key]

      Uploader.getImageHash(file)
        .then((hash: string) => {
          Uploader.uploadPic(file, this.uploadId, hash)
        })
        .then(() => {
          delete queue.pics[key]
          store.commit('changeActivityQueue', queue)
          store.commit('changeUploadingFile', file)
          this.processUploadList(store)
        })
        .catch((e) => {
          Errors.logError(e)
        })
    } else {
      this.running = false
      this.uploadName = null
      this.uploadName = null
      store.commit('changeActivityQueue', null)
      store.commit('changeUploadingFile', null)
    }
  }

  private static getImageHash(file: File): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader()
      // closure function providing the filename when the file is read into memory
      const loadHandler = (event) => {
        // need to convert arraybuffer to buffer for the md5 hash
        // in the app, we get an arraybuffer, on the server we get a node buffer
        // this code below converts arraybuffer to buffer
        // we md5 the result so this result will match the md5 result we have
        // when the file is uploaded.
        const buf = Buffer.alloc(event.target.result.byteLength)
        const view = new Uint8Array(event.target.result)
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < buf.length; ++i) {
          buf[i] = view[i]
        }
        const hash: string = Md5(buf)
        resolve(hash)
      }
      reader.onload = loadHandler
      reader.readAsArrayBuffer(file)
    })
  }

  /**
   * Makes necessary calls to create a pic record and upload the pic file
   * @param file: File        Pic file
   * @param uploadId: string  Id of upload folder
   * @param hash: string      md5 hash of the image buffer
   * @returns Promise<void>
   */
  private static uploadPic(file: File, uploadId: string, hash: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const fileName = file.name
      Uploader.postPic(fileName, uploadId, hash)
        .then((uploadUrl) => Uploader.putFile(uploadUrl, file))
        .then(() => {
          resolve()
        })
        .catch(() => {
          Errors.logError(`Failed to upload file ${fileName}`)
          reject()
        })
    })
  }

  /**
   * Call API to create a pic record, get back the signed upload url
   *
   * @param fileName: string  Pic name to be stored
   * @param uploadId: string  Folder Id to store the pic
   * @param hash: string      md5 hash of the image buffer
   * @returns Promise<string> contains the upload url to push the pic to the server
   */
  private static postPic(fileName: string, uploadId: string, hash: string): Promise<string> {
    const headers: Map = { Authorization: localStorage.tk }
    const body: string = JSON.stringify({
      name: fileName,
      // eslint-disable-next-line @typescript-eslint/camelcase
      upload_id: uploadId,
      hash,
    })

    return new Promise((resolve, reject) => {
      fetch(Urls.url('createPic'),
        {
          method: 'POST',
          headers,
          body,
        })
        .then((response: Response) => response.json())
        .then((data: CreatePicResponse) => {
          if (data.status !== 'Success') {
            Errors.logError('Failed to create pic record')
            Errors.logError(data.message)
            reject()
          } else {
            resolve(data.data.signed_url)
          }
        })
        .catch((err: string) => {
          Errors.logError('Failed to create pic record')
          Errors.logError(err)
          console.log('failed upload', err)
          reject()
        })
    })
  }

  /**
   * Upload a pic file to a signed S3 url
   *
   * @param url: string   Signed s3 url to upload a file
   * @param file: File    Pic file object
   * @return Promise<void>
   */
  private static putFile(url: string, file: File): Promise<void> {
    const headers: Map = { 'Content-Type': file.type }
    return new Promise((resolve, reject) => {
      fetch(url,
        {
          method: 'PUT',
          headers,
          body: file,
        })
        .then(() => resolve())
        .catch((err: string) => {
          Errors.logError('Failed to create pic record')
          Errors.logError(err)
          reject()
        })
    })
  }
}
