import Errors from '@/utilities/Errors'
import Uploader from '@/utilities/Uploader'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './plugins/vuex'

Vue.config.productionTip = false

Vue.config.errorHandler = (err) => {
  Errors.logError(err.toString())
  router.push('/error')
}

Vue.prototype.$uploader = new Uploader()

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app')
