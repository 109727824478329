// eslint-disable-next-line import/extensions
import { ApiMap } from '@/interfaces/main.d.ts'

const assets: ApiMap = {
  iss: 'https://files.simply.pics/jwt',
  devHost: 'https://api.simply.pics',
  prodHost: 'https://api.simply.pics',
  auth: '/auth/v1/login',
  authRefresh: '/auth/v1/refresh',
  books: '/book/v1/books',
  book: '/book/v1/book/:bookid',
  uploads: '/folder/v1/folders',
  upload: '/folder/v1/id/:uploadid',
  createUpload: '/folder/v1/folder',
  createPic: '/photo/v1/photo',
  route: '/route/v1/route',
}

export default assets
